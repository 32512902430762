import { motion, useAnimation } from "framer-motion"
import React, { useEffect } from "react"

function AnimatedArrow({ onHover, fill = "white", arrowEndColour = "black" }) {
  const controls = useAnimation()

  const arrowTipVariants1 = {
    hidden: {
      pathLength: 1,
      // rotate: 0,
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    visible: {
      pathLength: 0,
      // rotate: 90,
      x: 10,

      opacity: 0,

      transition: {
        delay: 0,
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  }

  const arrowTipVariants2 = {
    hidden: {
      pathLength: 0,
      stroke: `${fill} `,
      transition: {
        delay: 0,
        duration: 0.7,
        ease: "easeInOut",
      },
    },
    visible: {
      pathLength: 1,
      stroke: arrowEndColour,

      transition: {
        delay: 0.3,
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  }

  const shaftVariants = {
    hidden: {
      //   pathLength: 0,
      stroke: `${fill} `,
      transition: {
        delay: 0,
        duration: 0.7,
        ease: "easeInOut",
      },
    },
    visible: {
      //   pathLength: 1,
      stroke: arrowEndColour,

      transition: {
        delay: 0.3,
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  }
  const svgVariants = {
    hidden: {
      scale: 1,
      transition: {
        delay: 0,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    visible: {
      scale: 1.5,

      transition: {
        delay: 0.1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  }

  useEffect(() => {
    if (onHover) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, onHover])

  return (
    <motion.svg
      variants={svgVariants}
      animate={controls}
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M0.5 4.89746H10.4673V5.43998H0.5V4.89746Z"
        stroke={`${fill} `}
        variants={shaftVariants}
      />
      <motion.path
        animate={controls}
        variants={arrowTipVariants1}
        transition={{ duration: 0.3, ease: "easeIn" }}
        d="M11.0116 5.50652L11.3217 5.1685L11.0116 4.83048L7.5711 1.08038L7.8836 0.739701L11.9455 5.16852L7.8836 9.5973L7.5711 9.25662L11.0116 5.50652Z"
        stroke={`${fill} `}
      />
      <motion.path
        animate={controls}
        variants={arrowTipVariants2}
        transition={{ duration: 0.3, ease: "easeIn" }}
        d="M11.0116 5.50652L11.3217 5.1685L11.0116 4.83048L7.5711 1.08038L7.8836 0.739701L11.9455 5.16852L7.8836 9.5973L7.5711 9.25662L11.0116 5.50652Z"
        stroke={`${fill} `}
      />
    </motion.svg>
  )
}

export default AnimatedArrow
