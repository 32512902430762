import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useState } from "react"
import AnimatedArrow from "./arrows/AnimatedArrow"

function DefaultButton({
  url,
  title,
  className = "",
  dark = false,
  white = false,
  containerClass = "",
  motionDivClass = "",
  download,
  externalLink = false,
  arrowEndColour,
  ...other
}) {
  const [onHover, setOnHover] = useState(false)

  return (
    <div className={`${containerClass}`} {...other}>
      {download ? (
        <a
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          download
          className={`text-15 flex items-center px-8 py-6 leading-[1.2] cursor-pointer ${
            dark ? "bg-black" : white ? "bg-white" : "bg-beige"
          } rounded-full font-semibold ${className}`}
          href={url}
        >
          <span className="mr-4">{title} </span>

          <AnimatedArrow
            onHover={onHover}
            arrowEndColour={dark ? "white" : arrowEndColour}
            fill={` ${dark ? "white" : "black"}`}
          />
        </a>
      ) : externalLink ? (
        <a
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className={`text-15 flex items-center px-8 py-6 leading-[1.2] cursor-pointer ${
            dark ? "bg-black" : white ? "bg-white" : "bg-beige"
          } rounded-full font-semibold ${className}`}
          href={url}
        >
          <span className="mr-4">{title} </span>

          <AnimatedArrow
            onHover={onHover}
            arrowEndColour={dark ? "white" : arrowEndColour}
            fill={` ${dark ? "white" : "black"}`}
          />
        </a>
      ) : (
        <motion.div
          className={`${motionDivClass}`}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <Link
            className={`text-15 flex items-center px-8 py-6 leading-[1.2] overflow-visible ${
              dark ? "bg-black" : white ? "bg-white" : "bg-beige"
            } rounded-full font-semibold ${className}`}
            to={url || "#"}
          >
            <span className="mr-4">{title} </span>

            <AnimatedArrow
              onHover={onHover}
              arrowEndColour={dark ? "white" : arrowEndColour}
              fill={` ${dark ? "white" : "black"}`}
            />
          </Link>
        </motion.div>
      )}
    </div>
  )
}

export default DefaultButton
